import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
import { responseHandler } from './api';
export var linksApi = createApi({
    reducerPath: 'linksApi',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().linksApi,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['Links'],
    endpoints: function (builder) { return ({
        getLinks: builder.query({
            query: function () { return ({
                url: 'api/private/links/tenant',
                // params: filters,
                responseHandler: function (response) { return responseHandler(response, 'Links'); }
            }); },
            providesTags: function (result) {
                return result;
            }
        })
    }); }
});
export var useGetLinksQuery = linksApi.useGetLinksQuery;
